module.exports = {
	getEnvironment: () => process.env.REACT_APP_ENV || 'dev',
	isProductionEnv: () => process.env.REACT_APP_ENV === 'prod',
	graphQlEndpoint: () => `https://${domain.graphQl[ module.exports.getEnvironment() ]}`,
	apiEndpoint: ( endpoint ) => `https://${domain.api[ module.exports.getEnvironment() ]}${apiEndpoints[ endpoint ]}`,
	cloudfrontEndpoint: () => `https://${domain.cloudfront[ module.exports.getEnvironment() ]}`,
	SECURITY_URL: `${process.env.REACT_APP_HOME_URL}/security/`,
	PRIVACY_POLICY_URL: `${process.env.REACT_APP_HOME_URL}/privacy-policy/`,
	TERMS_OF_SERVICE_URL: `${process.env.REACT_APP_HOME_URL}/terms-of-service/`,
	HOME_UTM_SOURCE_CSP_URL: `${process.env.REACT_APP_HOME_URL}?utm_source=csp`,
	LOGIN_REFRESH: 5 * 60 * 1000, // 5 minutes
	LOGIN_REFRESH_MINIMUM_TIME: 20 * 1000, // 20 seconds
	FINANCE_REDIRECT_TIME: 10 * 1000, // 5 seconds
	PAYMENT_PLAN_NO_EMAIL_WARNING: ( totalAmount ) =>
		`Great news! If you need to break up your payment, your payment of ${totalAmount?.toLocaleString(
			'en-US',
			{ style: 'currency', currency: 'USD' },
		)} qualifies for a Payment Plan option. Unfortunately, this provider requests that you call them to set up a payment plan.`,
	PAYMENT_PLAN_MESSAGE: ( totalAmount ) =>
		`Great news! If you need to break up your payment, your payment of ${totalAmount?.toLocaleString(
			'en-US',
			{ style: 'currency', currency: 'USD' },
		)} qualifies for a Payment Plan option. Please fill out the terms below & select your payment method.`,

	publicKeyRaw: () => publicKey[ module.exports.getEnvironment() ],
};

// ❗ dev urls do not contain the region (use1) by design ❗
const domain = {
	graphQl: {
		demo: 'csp-graphql.use1.demo.be.payground.com/graphql',
		int: 'csp-graphql.use1.int.be.payground.com/graphql',
		dev: 'csp-graphql.dev.be.payground.com/graphql',
		prod: 'csp-graphql.use1.prod.be.payground.com/graphql',
		stage: 'csp-graphql.use1.stage.be.payground.com/graphql',
		pr01: 'csp-graphql.use1.pr01.be.payground.com/graphql',
		pr02: 'csp-graphql.use1.pr02.be.payground.com/graphql'
	},
	api: {
		demo: 'api.use1.demo.be.payground.com',
		int: 'api.use1.int.be.payground.com',
		dev: 'api.dev.be.payground.com',
		prod: 'api.use1.prod.be.payground.com',
		stage: 'api.use1.stage.be.payground.com',
		pr01: 'api.use1.pr01.be.payground.com',
		pr02: 'api.use1.pr02.be.payground.com'
	},
	cloudfront: {
		demo: 'd25ienhzet5u26.cloudfront.net',
		int: 'd25ienhzet5u26.cloudfront.net',
		dev: 'd25ienhzet5u26.cloudfront.net',
		prod: 'd25ienhzet5u26.cloudfront.net',
		stage: 'd25ienhzet5u26.cloudfront.net',
		pr01: 'd25ienhzet5u26.cloudfront.net',
		pr02: 'd25ienhzet5u26.cloudfront.net'
	},
};

const apiEndpoints = {
	verifyIdentity: '/auth/v1/token/quickPay',
};

const publicKey = {
	demo: '{"key_ops":["encrypt"],"ext":true,"kty":"RSA","n":"0SVsauiPgzoHpugkmSJ98vbwLj6ZYWgCfWu4gpQLiKIWEbGPXoxYD1beJmGAEHHaYa49s2IhxAVuXwt2MaOV8GWwXneiHuORayg1F13OdtCZRZmcq7JlNDXT-fxbLtZ5RpiNy9pcr_w7jySA76uppR8fTiDJqOSwkhII7rDmM8y7txp3xrr8UmMtaOQ9muzdImrrRGd6uUMEc2vEMrULG4rA5pYu3shEe0ioVdJCpO77_Z-RUTOdXSjEq5LO8A6i-XUA43_nSmHGE44jIvbwfDrptlGYvWKqu0Sfv0Bs2DNFx1B_AiS8YUWCBaSDf2andd7JrNUxLDZ1kb1UA1dvTQ","e":"AQAB","alg":"RSA-OAEP-256"}',
	int: '{"key_ops":["encrypt"],"ext":true,"kty":"RSA","n":"ha3tgiGG8CZOmYUGekSS2sIRqxMhssOYrp8b-eHzdlC2qCp0M9wstTXZ5HPs8nigRf_-M6ygeVnkyyp98qHjdz2BHzo63bdJc_lHeLGWvRM3Q5rLUszOa9ezzXImR40Z7BujUW6HCHQg8PGQ7e0-8nx4XO8eeuu6pRTqZZz-MgqRdNTe2MwtDn1L5QCdOCZIgC-fjTg0PZYoW1N6qJunOpqNROgiO5F1zFTBKgMSsckz-j_Xa8bCSLnU3vR8iYKZfy20j18LUU3Af0vU7xRVEM9xaQCx6o6nOn1e9CCqd0IU6xX8ThjipEEVCymghRw8doB1NISJuEMXuPpn0TC4cw","e":"AQAB","alg":"RSA-OAEP-256"}',
	dev: '{"key_ops":["encrypt"],"ext":true,"kty":"RSA","n":"1DMZiidHTPlt7q-u8p3FBOvK1VH4uVL1sKVRTgrkDth6aDeRN89fqRHE88orRxtn7F-c5dSYOx6bWt5DeuRGBULVkGJoSRC1Qcw8eOSyfptN1GAlXq7LuwGgjsP3nPYk1TpY2s_zYk9LJQ8cDLqsez6FSTTmrdOkt5WbJzuUO3v61YZpNqTxjLEBIyNNk3fcgIoKJ0aJNN-jwmAiO69vGC8tkoyRQHeYVm3xjUMTrZbLoSvydWsMBWoxKQR5jTavbYOGDa46fMd4mQHukJ8-XkBTvPEeOCCf6-LcBwBDhEp1ycHKCxcEgBMgroP2Fb6kzFT7joa0u1D39v7MoofYJQ","e":"AQAB","alg":"RSA-OAEP-256"}',
	prod: '{"key_ops":["encrypt"],"ext":true,"kty":"RSA","n":"yMrd8_nrflFLKykp_yJTgIXmdvCxLxTb9fqlpP5VBynh8CtTRS9MJSsz9mcXuCsm6sagDb0-vfdOSEPzfmS57szx6n3KK6nZDlDSGGeeFvpyAE0mXXm33QShq4eQmPT2wyc9Qtbkl0KgsduQSZ7OqLHnhugSjQHJB2TCfL4wvMtVjv5RDnyw-WaMkooXnUuqTY_zx1DBl3mfkHl8JuG17Yd6msNCzP2TI2bg5flxS-I14fm1X-pFnNmh9udWQucQGW8Xk1FEOeBS2azYPtPTAnGaqn5DSYoyWqoLBkgWwNFIGZ5uC7H4T-lB3mTOpx1avapaYNWMGWkds7a9F7tqlQ","e":"AQAB","alg":"RSA-OAEP-256"}',
	stage: '{"key_ops":["encrypt"],"ext":true,"kty":"RSA","n":"uf3qVQUkbaDp0Rk1NrQG59y_TLF4jMx93pxOIe_d7LYo1iJBmW6va5MWBJyGW-GjXCyPvwcUA0PnACqvA5g8mZfKuKKkqh6sRS4QyuS89He_WTkoWW0oo56nJvIuU3Twz--cIXtU7mbrkc5geF0zhdmbr9Oq56OQd-U4SdYIGxqEDTXOR43eUTYRU6y395UoJWPDOPLpO-UUm68Ew17X0XhbzQHAeho2FLsQO3_qmfoREZTSAe7TfJSpGWnxXqlCWr0qk8XyAgma4LkHit-UhM-7NA4u5bjLzrEtsr_LM9npYtuYpZFKeGWzrWZNEyR1Bi0lD1BGRlMOL1mzlTvLFQ","e":"AQAB","alg":"RSA-OAEP-256"}',
	pr01: '{"key_ops":["encrypt"],"ext":true,"kty":"RSA","n":"lTB0940eH29pU2bUzWLLm1TghleLYz98IAplEaoGqqUAMMIJ5lLB2Bzw3v16rGb_Lc0gHRqqRST8LuwxamNg73uWjfAI1gznz-1FiWLr-VvOoG5wcTxpZmWwEqYWPIry-g-y__-Sw8j3Kj3r5w2QiPGIz75uYx-MkQGlkdG75CtZjbosFOc_xfibtDbEZ0GyJx0nNSyTO7TnuAjT-GV4RDeyiyQVPo-DrMtIrgAuV6dCGtebyX3jqXZZu9sUDPm-w5XWs_W-Pgl0C7S9KyGz6VK7hS2WE5rpH6d7tFw_UKIBs4dLnUl2fKK4LI-si3KvzRlrp_LTe9q71RI4EbcAZw","e":"AQAB","alg":"RSA-OAEP-256"}',
	pr02: '{"key_ops":["encrypt"],"ext":true,"kty":"RSA","n":"mUs4GCAqj6GBtjutrKLW9CWEntq6zi1bOVD30EGUSFLpvPsyG3CxyWm-4Tkf0n8lNH6156b-CMHzNs8-pLHKTashnvASZsDJY3UhysMhB7Ns8ORlCZYB6gQhbQ8BztZshZ-xu_HXPgcD2Eq0AfI5Psg92vQdej7YzgdFg2uIroM_06ij5-vWAsUxL1axcWEvhkIe0xrHHN5SYW55j7mogl4108Z13o8NP455fL4v36OjWBZ1x_jyISC0RHBKrkdcv-jT6Sqp48tRgex_pv1yM_ngTFu-sKdkeWF85UDnKcXIasBWKxBqMgT8pFC8N7-_DKOPrOF681GYYgYVw89GaQ","e":"AQAB","alg":"RSA-OAEP-256"}'
};
